<template>
  <validation-provider
    :name="name"
    mode="eager"
    :rules="rules"
    v-slot="{ errors }"
  >
    <b-form-group>
      <vue-simple-suggest
        :value="value"
        :mode="mode"
        :list="list"
        :styles="autoCompleteStyle"
        :display-attribute="textAttribute"
        :value-attribute="valueAttribute"
        :placeholder="placeholder"
        :filter-by-query="true"
        @select="select"
        ref="suggest"
      />
      <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
    </b-form-group>
  </validation-provider>
</template>

<script>
import VueSimpleSuggest from "vue-simple-suggest";
import "vue-simple-suggest/dist/styles.css";
export default {
  components: {
    VueSimpleSuggest,
  },
  props: {
    name: String,
    placeholder: String,
    rules: String,
    list: Array,
    textAttribute: String,
    valueAttribute: String,
    value: [Number, String],
    returnType: {
      type: String,
      default: "value",
    },
    mode: {
      type: String,
      default: "select",
    },
  },
  data() {
    return {
      autoCompleteStyle: {
        vueSimpleSuggest: "position-relative",
        inputWrapper: "",
        defaultInput: "form-control",
        suggestions: "position-absolute list-group z-1000",
        suggestItem: "list-group-item",
      },
      chosen: "",
    };
  },

  mounted() {},

  methods: {
    select(data) {
      if (data) {
        if (this.mode == "select" && this.returnType == "value") {
          this.$emit("input", Number(data.id));
        } else {
          this.$emit("input", Number(data));
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .vue-simple-suggest {
    .input-wrapper {
      position: relative;
      &::before {
        content: "";
        position: absolute;
        background-image: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' width='19.152' height='23.939' viewBox='0 0 19.152 23.939'%3E%3Cpath id='Path_889' data-name='Path 889' d='M9.576,0A9.577,9.577,0,0,0,0,9.577C0,18.195,7.661,23.94,9.576,23.94s9.576-5.746,9.576-14.362A9.577,9.577,0,0,0,9.576,0Zm0,13.407a3.83,3.83,0,1,1,3.83-3.83A3.831,3.831,0,0,1,9.576,13.408Z' transform='translate(0 -0.001)' fill='%239637f1'/%3E%3C/svg%3E%0A");
        background-repeat: no-repeat;
        width: 20px;
        height: 24px;
        top: 50%;
        left: 7px;
        transform: translateY(-50%);
        z-index: 1;
      }
      &::after {
        content: "";
        position: absolute;
        width: 9px;
        height: 14px;
        background-image: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' width='7.765' height='13.441' viewBox='0 0 7.765 13.441'%3E%3Cpath id='Path_210' data-name='Path 210' d='M4871.166,592.811l5.351,5.225-5.351,5.388' transform='translate(-4869.752 -591.396)' fill='none' stroke='%239637f1' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'/%3E%3C/svg%3E%0A");
        background-repeat: no-repeat;
        right: 19px;
        top: 50%;
        transform: translateY(-50%) rotate(90deg);
        position: absolute;
      }
      .form-control {
        padding-left: rem(32px);
        @media screen and (max-width: 991px) {
          padding-left: rem(40px);
        }
      }
    }
    &.designed {
      .suggestions {
        border: 0;
        box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.24);
        -webkit-box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.24);
        -moz-box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.24);
        border-radius: 8px;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        .suggest-item {
          padding: 0.5rem 1.25rem;
          user-select: none;
          font-size: 0.875rem;
          font-weight: 600;
          line-height: 1.1;
          &.hover {
            background: var(--primary) !important;
          }
          span {
            font-size: 0.875rem;
            font-weight: 600;
          }
        }
      }
    }
  }
  .suggestions {
    border: 0;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.24);
    -webkit-box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.24);
    -moz-box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.24);
    border-radius: 8px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    .suggest-item {
      padding: 0.5rem 1.25rem;
      user-select: none;
      font-size: 0.875rem;
      font-weight: 600;
      line-height: 1.1;
      &.hover {
        background: var(--primary) !important;
      }
      span {
        font-size: 0.875rem;
        font-weight: 600;
      }
    }
  }
}
</style>
