<template>
  <div class="locationFilter">
    <!-- Dropdown -->
    <base-multi-select
      :list="this.states"
      @input="input"
      :value="value"
      label="label"
      track-by="id"
    ></base-multi-select>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      states: [
        {
          id: null,
          label: "All",
        },
        {
          id: "active",
          label: "Active",
        },
        {
          id: "pending",
          label: "Pending",
        },
        {
          id: "revision",
          label: "Revision",
        },
        {
          id: "completed",
          label: "Completed",
        },
        {
          id: "rejected",
          label: "Rejected",
        },
      ],
    };
  },
  props: {
    value: {
      type: [Object, Array, String],
      default: null,
    },
    selected: null,
  },
  computed: {
    ...mapGetters({ locations: "locations" }),
  },
  methods: {
    input(value) {
      this.$emit("input", value);
    },
  },
};
</script>
