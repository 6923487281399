import { MEDIA_ACCOUNT } from "@/graphql/user/query";
import { AVAILABLE_MEDIA_ACCOUNTS } from "@/graphql/common/query";
import { apolloClient } from "@/vue-apollo";
const state = {
  influencerAccounts: [],
  list: [],
};
const getters = {
  influencerAccounts: (state) => state.influencerAccounts,
  list: (state) => state.list,
};
const mutations = {
  SET_INFLUENCERACCOUNTS(state, mediaAccounts) {
    state.influencerAccounts = mediaAccounts;
  },
  SET_ALLACCOUNTS(state, mediaAccounts) {
    state.list = mediaAccounts;
  },
};
const actions = {
  async setMediaAccount({ commit }, payload = null) {
    await apolloClient
      .query({ query: MEDIA_ACCOUNT, fetchPolicy: "network-only"})
      .then((data) => {
        if (data) {
          if (payload) commit("SET_INFLUENCERACCOUNTS", payload);
          else commit("SET_INFLUENCERACCOUNTS", data.data.mediaAccounts);
        }
      })
      .catch((e) => {});
  },
  async allAccounts({ commit }) {
    await apolloClient
      .query({ query: AVAILABLE_MEDIA_ACCOUNTS, fetchPolicy: "network-only" })
      .then((data) => {
        if (data) {
          commit("SET_ALLACCOUNTS", data.data.availableMediaAccounts);
        }
      })
      .catch((e) => {});
  },
};
export const mediaAccounts = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
