<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "App",
  computed: {
    ...mapGetters(["isAuthenticated"]),
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      getBalance: "getBalance",
    }),
  },
  mounted() {
    if (this.isAuthenticated) {
      this.getBalance();
    }
  },
};
</script>

<style lang="scss"></style>
