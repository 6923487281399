<template>
  <div v-if="alert.message != null" :class="'alert ' + alert.type">
    {{ alert.message }}
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState({
      alert: (state) => state.alert,
    }),
  },
};
</script>

<style></style>
