<template>
  <div>
    <b-form-group>
      <multiselect
        :options="list"
        :multiple="multiple"
        :label="label"
        :track-by="id"
        @input="selectedValue"
        :value="value"
        :placeholder="placeHolder"
        :class="errorClass"
      >
      </multiselect>
    </b-form-group>
  </div>
</template>

<script>
export default {
  props: {
    list: [Object, Array],
    multiple: {
      type: Boolean,
      default: false,
    },
    label: String,
    id: String,
    value: {
      type: [Object, Array, String, Number],
      default: null,
    },
    placeHolder: {
      type: String,
      default: "Select",
    },
    errorClass: {
      type: Object,
      default: null,
    },
  },
  methods: {
    selectedValue(value) {
      this.$emit("input", value);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep.multiselect {
  min-width: 200px;
  width: auto;
  @media screen and (max-width: 575px) {
    margin: 0 0 14px 0;
    min-width: 100%;
  }
  &__select {
    width: 30px;
    height: 18px;
    right: 5px;
    top: 10px;
    &::after {
      content: "";
      position: absolute;
      width: 8px;
      height: 14px;
      border: 0;
      background-image: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' width='7.765' height='13.441' viewBox='0 0 7.765 13.441'%3E%3Cpath id='Path_210' data-name='Path 210' d='M4871.166,592.811l5.351,5.225-5.351,5.388' transform='translate(-4869.752 -591.396)' fill='none' stroke='%239637f1' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'/%3E%3C/svg%3E%0A");
      background-repeat: no-repeat;
      right: 12px;
      transform: translateY(-50%) rotate(90deg);
      top: 50%;
    }
    &::before {
      border: 0;
    }
  }
  &__tags {
    border-radius: 8px;
    border: 0;
    min-height: 42px;
    background: #fff;
    padding-right: 27px;
    padding-top: 10px;
  }
  &__tag {
    position: relative;
    background: var(--primary);
    min-width: 40px;
    font-size: rem(14px);
    font-weight: 700;
  }
  &__tag-icon {
    line-height: 19px;
    @media screen and (max-width: 991px) {
      line-height: 17px;
    }
    &:after {
      color: #fff;
    }
    &:hover {
      background: unset;
      opacity: 0.7;
    }
  }
  &__input {
    display: none;
  }
  &__option--highlight {
    background: var(--primary);
    outline: none;
    color: #fff;
  }
  &__option {
    background: transparent !important;
    color: var(--primary);
    font-size: rem(16px);
    &:hover {
      background: var(--primary) !important;
      color: #fff;
    }
    &--highlight {
      &::after {
        display: none;
      }
    }
    &--selected {
      &.multiselect__option--highlight {
        color: var(--primary);
      }
      &:after {
        display: none;
      }
    }
    span {
      text-transform: capitalize;
    }
  }
  &__content-wrapper {
    overflow: hidden;
    max-height: unset !important;
  }
  &__placeholder {
    padding-top: 5px;
  }
  &__single {
    text-transform: capitalize;
  }
}
::v-deep {
  .multiselect__option--selected.multiselect__option--highlight {
    color: var(--primary);
  }
}
</style>
