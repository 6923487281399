<template>
  <validation-provider
    :name="name"
    mode="eager"
    :rules="rules"
    v-slot="{ errors }"
  >
    <b-form-group :label="label" :class="wrapperClass">
      <b-form-radio-group
        :state="errors[0] ? false : null"
        :options="options"
        :name="name"
        @input="input"
        :checked="value"
        plain
      ></b-form-radio-group>
      <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
    </b-form-group>
  </validation-provider>
</template>

<script>
export default {
  props: {
    name: String,
    value: [String, Boolean, Number],
    rules: String,
    label: String,
    options: Array,
    wrapperClass: {
      default: "",
      type: String,
    },
  },
  methods: {
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    input(value) {
      this.$emit("input", value);
    },
  },
};
</script>

<style lang="scss" scoped>
.radio-button {
  div[role="radiogroup"] {
    @include flex(center, flex-start);

    ::v-deep {
      --bgColor: #f7effe;
      .form-check {
        padding: 0;
        input[type="radio"] {
          display: none;
        }
        label {
          margin-left: 0;
          background: var(--bgColor);
          height: 40px;
          padding: rem(8px) rem(50px);
          min-width: rem(120px);
          border-radius: 6px;
          color: var(--primary);
          font-size: rem(14px);
          font-weight: 700;
          text-align: center;
          display: flex;
          align-items: center;
          justify-content: center;
          &:after,
          &:before {
            display: none !important;
          }
          span {
            display: inline-block;
          }
        }
        input[type="radio"]:checked {
          + label {
            color: #fff;
            background: var(--primary);
          }
        }
      }
    }
  }
  &.radio-pills {
    div[role="radiogroup"] {
      ::v-deep {
        label {
          min-width: rem(65px);
          padding: rem(5px) rem(10px);
          border-radius: 12px;
          height: auto;
        }
      }
    }
  }
}
::v-deep {
  .bv-no-focus-ring {
    display: flex;
    justify-content: space-between;
    .form-check-inline {
      display: flex;
      align-items: center;
      margin: 0;
      &:not(:last-child) {
        margin-right: rem(16px);
      }
      .form-check-input {
        width: 22px;
        min-width: 22px;
        height: 22px;
        padding: 0;
        margin-top: 0;
        border: 2px solid #ccd4e0;
        cursor: pointer;
        &:checked {
          background-color: var(--primary);
          border-color: var(--primary);
        }
        &:focus {
          box-shadow: none;
        }
        &.is-invalid {
          border: 2px solid #dc3545;
        }
      }
      .form-check-label {
        margin-left: 10px;
        font-size: rem(16px);
        color: var(--textPrimary);
        cursor: pointer;
      }
    }
    &.col-form-label {
      font-size: rem(16px);
      font-weight: 500;
      padding-bottom: 12px;
    }
  }
  .invalid-feedback {
    display: block !important;
  }
}
</style>
