import { onLogin, apolloClient, onLogout } from "@/vue-apollo";
import { LOGIN_USER, REGISTER_USER } from "@/graphql/common/mutations";
import { LOGGED_IN_USER, BALANCE } from "@/graphql/common/query";
import utilities from "@/mixin/utilities";
import exceptions from "@/mixin/exceptions";

// import apolloClient from 'vue-apollo'
const state = {
  token: null,
  user: {},
  authStatus: false,
  balance: {},
  showTour: 1,
};
const getters = {
  isAuthenticated: (state) => !!state.token,
  authStatus: (state) => state.authStatus,
  user: (state) => state.user,
  balance: (state) => state.balance,
};
const mutations = {
  SET_TOKEN(state, token) {
    state.token = token;
  },
  LOGIN_USER(state, user) {
    state.authStatus = true;
    state.user = { ...user };
    state.user.currency = state.balance.currency;
    state.user.balance = state.balance.amount;
    state.showTour = user.showTour;
  },
  LOGOUT_USER(state) {
    state.token = null;
    state.authStatus = false;
    state.user = {};
    state.balance = {};
  },
  UPDATE_USER(state, user) {
    state.user = { ...user };
    state.user.currency = state.balance.currency;
    state.user.balance = state.balance.amount;
  },
  SET_BALANCE(state, balance) {
    state.balance = { ...balance };
    state.user.currency = balance.currency;
    state.user.balance = balance.amount;
  },
  UPDATE_TOUR(state, status) {
    state.showTour = status;
  },
};
const actions = {
  login({ commit, dispatch }, authDetails) {
    return new Promise((resolve, reject) => {
      apolloClient
        .mutate({
          mutation: LOGIN_USER,
          variables: { ...authDetails },
        })
        .then((data) => {
          // Result
          if (data) {
            const token = JSON.stringify(data.data.createSession.token);
            commit("SET_TOKEN", token);
            onLogin(apolloClient, token).then(() => {
              dispatch("setUser");
            });
          }
          resolve();
        })
        .catch((e) => {
          let error = utilities.methods.getErrorMessage(e);
          commit("alert/error", error);
          reject(e);
        });
    });
  },
  register({ commit, dispatch }, authDetails) {
    return new Promise((resolve, reject) => {
      apolloClient
        .mutate({
          mutation: REGISTER_USER,
          variables: { ...authDetails },
        })
        .then((data) => {
          if (data) {
            const token = JSON.stringify(data.data.registerUser.token);
            commit("SET_TOKEN", token);
            onLogin(apolloClient, token).then(() => {
              dispatch("setUser");
            });
            resolve();
          }
        })
        .catch((e) => {
          exceptions.methods.handleError(e);
          reject(e);
        });
    });
  },

  async setUser({ commit, dispatch }) {
    await apolloClient
      .query({ query: LOGGED_IN_USER })
      .then((data) => {
        if (data) {
          commit("LOGIN_USER", data.data.me);
          dispatch("getBalance");
          dispatch("mediaAccounts/allAccounts");
          dispatch("mediaAccounts/setMediaAccount");
          dispatch("getLocations");
          dispatch("getCategories");
        }
        if (data.data.me.type == 1) {
          dispatch("campaign/setDefaultFilters");
        }
      })
      .catch((e) => {
        exceptions.methods.handleError();
      });
  },
  async updateUser({ commit }) {
    await apolloClient
      .query({
        query: LOGGED_IN_USER,
        fetchPolicy: "network-only",
      })
      .then((data) => {
        if (data) {
          commit("UPDATE_USER", data.data.me);
        }
      })
      .catch((e) => {
        exceptions.methods.handleError();
      });
  },
  async logOut({ commit }) {
    commit("LOGOUT_USER");
    onLogout(apolloClient);
  },
  async getBalance({ commit }) {
    await apolloClient
      .query({
        query: BALANCE,
        fetchPolicy: "network-only",
      })
      .then((data) => {
        if (data) {
          commit("SET_BALANCE", data.data.getBalance);
        }
      })
      .catch((e) => {
        exceptions.methods.handleError();
      });
  },
};
export default {
  state,
  getters,
  mutations,
  actions,
};
