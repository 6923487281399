import gql from "graphql-tag";

export const LOGIN_USER = gql`
  mutation createSession($email: String!, $password: String!) {
    createSession(email: $email, password: $password) {
      token
    }
  }
`;

export const REGISTER_USER = gql`
  mutation registerUser($email: String!, $password: String!, $type: Int!) {
    registerUser(email: $email, password: $password, type: $type) {
      token
    }
  }
`;
export const UPDATE_USER = gql`
  mutation updateUser(
    $firstName: String
    $lastName: String
    $birthdate: String
    $address: String
    $idLocation: Int
    $idCurrency: Int
    $phone: String
    $gender: String
  ) {
    updateUser(
      firstName: $firstName
      lastName: $lastName
      birthdate: $birthdate
      address: $address
      idLocation: $idLocation
      idCurrency: $idCurrency
      phone: $phone
      gender: $gender
    ) {
      state
    }
  }
`;
export const UPLOAD_IMAGE = gql`
  mutation uploadProfile($image: Upload) {
    uploadProfile(image: $image) {
      value
    }
  }
`;
export const RESEND_PIN = gql`
  mutation resendPin {
    resendPin {
      state
      msg
    }
  }
`;
export const UPDATE_PASSWORD = gql`
  mutation updatePassword($password: String, $retype: String) {
    updatePassword(password: $password, retype: $retype) {
      state
      msg
    }
  }
`;
