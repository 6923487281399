<template>
  <div class="socialAccounts">
    <validation-provider
      :name="name"
      mode="eager"
      :rules="rules"
      v-slot="{ errors }"
    >
      <b-form-group :label="label">
        <b-form-radio-group
          @input="input"
          :checked="value"
          buttons
          name="socialAccounts"
          :state="errors[0] ? false : null"
        >
          <b-form-radio
            v-for="(mediaAccount, i) in mediaAccounts"
            :key="i + 1"
            :value="mediaAccount.value"
          >
            <img
              :src="`${$config.IMG_HOST}/20x20/${mediaAccount.logo}`"
              alt=""
              class="img-fluid"
            />
            {{ mediaAccount.text }}
          </b-form-radio>
        </b-form-radio-group>
        <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
      </b-form-group>
    </validation-provider>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    value: [String, Boolean, Number, Array],
    label: String,
    name: String,
    rules: String,
  },
  computed: {
    ...mapGetters({ mediaAccounts: "mediaAccounts/list" }),
  },
  methods: {
    input(value) {
      this.$emit("input", value);
    },
  },
};
</script>

<style lang="scss" scopped>
.socialAccounts {
  input {
    opacity: 0;
    visibility: hidden;
  }
  .btn-group-toggle {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    > div {
      flex: 0 0 45%;
      max-width: 45%;
      margin-bottom: rem(12px);
    }
    label {
      margin: 5px 5px;
    }
  }
  .btn {
    font-size: 14px;
    font-weight: 600;
    color: var(--textPrimary) !important;
    border: 1px solid #caced5 !important;
    border-radius: 50px !important;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding: 1.125rem 0.625rem !important;
    text-decoration: none;
    min-width: auto !important;
    min-height: 65px !important;
    background-color: #fff !important;
    @media screen and (max-width: 991px) {
      min-height: 40px !important;
    }
    img {
      margin-right: 10px;
    }
    &.active {
      background: var(--primary) !important;
      color: #fff !important;
    }
  }
}
</style>
