import gql from "graphql-tag";
import {
  CORE_INFLUENCER_FIELDS,
  CORE_MEDIA_ACCOUNTS_FIELDS,
  CORE_SUBSCRIBERS_FIELDS,
  CORE_CAMPAIGN_FIELDS,
  CAMPAIGN_DETAILS_FIELDS,
  CAMPAIGN_SUBSCRIPTION_FIELDS,
  CAMPAIGN_MEDIA_ACCOUNT_FIELDS,
  CORE_OFFER_FIELDS,
  CORE_LOCATIONS_FIELDS,
  CORE_TAGS,
} from "../fragment";
export const CAMPAIGN_FILTER = gql`
  ${CORE_LOCATIONS_FIELDS}
  ${CORE_TAGS}
  query {
    newCampaignFilters {
      followers
      priceStart
      priceEnd
      influencers
      posts
      interests {
        value: id
        text: name
      }
      gender {
        value: id
        text: name
      }
      locations {
        ...CoreLocationFields
      }
      tags {
        ...CoreTags
      }
      default {
        followers
        priceStart
        priceEnd
        rating
        ageStart
        ageEnd
        gender
        location
        interests
        influencers
        posts
        tag
      }
    }
  }
`;
export const CAMPAIGN_ESTIMATE = gql`
  query campaignEstimate($filter: InputFilter) {
    campaignEstimate(filter: $filter) {
      location
      potential
      influencers
      reach
      engagement
      budget
      tag
    }
  }
`;
export const OFFER_REACH = gql`
  query offerReach($followers: Int!) {
    offerReach(followers: $followers)
  }
`;
export const INFLUENSER = gql`
  ${CORE_INFLUENCER_FIELDS}
  ${CORE_MEDIA_ACCOUNTS_FIELDS}
  query ($page: Int, $limit: Int, $location_id: Int, $account_id: Int) {
    influencers(
      page: $page
      limit: $limit
      location_id: $location_id
      account_id: $account_id
    ) {
      ...CoreInfluencerFields
      accounts {
        ...CoreMediaAccountsFields
      }
    }
  }
`;
export const INFLUENSER_DETAIL = gql`
  ${CORE_INFLUENCER_FIELDS}
  ${CORE_MEDIA_ACCOUNTS_FIELDS}
  query influencer($id: Int) {
    influencer(id: $id) {
      ...CoreInfluencerFields
      accounts {
        ...CoreMediaAccountsFields
      }
    }
  }
`;
export const INFLUENSER__CAMPAIGNS = gql`
  ${CORE_CAMPAIGN_FIELDS}
  ${CAMPAIGN_SUBSCRIPTION_FIELDS}
  query influencerCampaigns($id: Int!, $limit: Int) {
    influencerCampaigns(id: $id, limit: $limit) {
      ...CoreCampaignFields
      subscription {
        ...CampaignSubscriptionFields
      }
    }
  }
`;

export const My_CAMPAIGNS = gql`
  ${CORE_CAMPAIGN_FIELDS}
  ${CAMPAIGN_DETAILS_FIELDS}
  ${CAMPAIGN_SUBSCRIPTION_FIELDS}
  query myCampaigns($page: Int, $limit: Int, $state: String) {
    myCampaigns(page: $page, limit: $limit, state: $state) {
      ...CoreCampaignFields
      details {
        ...CampaignDetailsFields
      }
      subscription {
        ...CampaignSubscriptionFields
      }
    }
  }
`;
export const CAMPAIGN_DETAILS = gql`
  ${CORE_CAMPAIGN_FIELDS}
  ${CAMPAIGN_SUBSCRIPTION_FIELDS}
  ${CAMPAIGN_MEDIA_ACCOUNT_FIELDS}
  query campaign($id: Int) {
    campaign(id: $id) {
      ...CoreCampaignFields
      subscription {
        ...CampaignSubscriptionFields
      }
      mediaAccount {
        ...CampaignMediaAccountFields
      }
      mediaType
    }
  }
`;
export const OFFER_SUBSCRIBERS = gql`
  ${CORE_SUBSCRIBERS_FIELDS}
  query offerSubscribers($id: Int!, $state: String) {
    offerSubscribers(id: $id, state: $state) {
      ...Subscriber
    }
  }
`;
export const CAMPAIGN_SUBSCRIBERS = gql`
  ${CORE_SUBSCRIBERS_FIELDS}
  query campaignSubscribers($id: Int!, $state: String) {
    campaignSubscribers(id: $id, state: $state) {
      ...Subscriber
    }
  }
`;

export const CAMPAIGN_STATS = gql`
  query ($id: Int!) {
    campaignStats(id: $id) {
      subscribers {
        pending
        active
        revision
        completed
      }
      budgetUsed
    }
  }
`;

export const MY_OFFERS = gql`
  ${CORE_OFFER_FIELDS}
  query myOffers($page: Int, $limit: Int, $state: String) {
    myOffers(page: $page, limit: $limit, state: $state) {
      ...CoreOfferFields
    }
  }
`;

export const OFFER = gql`
  ${CORE_OFFER_FIELDS}
  query offer($id: Int!) {
    offer(id: $id) {
      ...CoreOfferFields
      state
      mediaAccount {
        id
        name
        logo
      }
    }
  }
`;
export const TRANSACTIONS = gql`
  query transactions(
    $page: Int
    $limit: Int
    $startDate: String
    $endDate: String
  ) {
    transactions(
      page: $page
      limit: $limit
      startDate: $startDate
      endDate: $endDate
    ) {
      date
      amount
      for
    }
  }
`;
export const FUNDS = gql`
  query funds($page: Int, $limit: Int, $fromDate: String, $toDate: String) {
    funds(page: $page, limit: $limit, fromDate: $fromDate, toDate: $toDate) {
      timestamp
      amount
      state
    }
  }
`;
export const CAMPAIGNS_STATS = gql`
  query {
    campaignsStats {
      usedBudget
      totalBudget
    }
  }
`;
