var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-provider',{attrs:{"name":_vm.name,"mode":"eager","rules":_vm.rules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('b-form-group',[_c('b-form-datepicker',{attrs:{"placeholder":"Birth date","value":_vm.value,"state":errors[0] ? false : valid ? true : null,"locale":"en-US","date-format-options":{
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
      }},on:{"input":_vm.input}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }