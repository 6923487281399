<template>
  <div>
    <form id="paymentform">
      <b-form-group>
        <div class="form-control" id="cc-name"></div>
        <div class="invalid-feedback">
          {{ fieldErrors.cardName }}
        </div>
      </b-form-group>
      <b-form-group>
        <div class="form-control" id="cc-number"></div>
        <div class="invalid-feedback">
          {{ fieldErrors.cardNumber }}
        </div>
      </b-form-group>
      <div class="row align-items-center col-gap-10">
        <div class="col-md-4 col-4">
          <b-form-group>
            <div class="form-control" id="cc-expiration"></div>
            <div class="invalid-feedback">
              {{ fieldErrors.cardExpiry }}
            </div>
          </b-form-group>
        </div>
        <div class="col-md-4 col-4">
          <b-form-group>
            <div class="form-control" id="cc-cvv"></div>
            <div class="invalid-feedback">
              {{ fieldErrors.cardCvv }}
            </div>
          </b-form-group>
        </div>
        <div class="col-md-4 hide-on-md">
          <p>3/4 digital code</p>
        </div>
      </div>
      <button
        type="button"
        :class="isSubmitting ? 'processing' : ''"
        class="btn btn-primary full mt-10"
        id="pay"
      >
        Add Money with Card
      </button>
    </form>
  </div>
</template>

<script>
var client = require("braintree-web/client");
var hostedFields = require("braintree-web/hosted-fields");
export default {
  data() {
    return {
      fieldErrors: {
        cardName: "Cardholder name is required",
        cardNumber: "Credit card number is required",
        cardExpiry: "Card expiry is required",
        cardCvv: "Card security code is required",
      },
      isSubmitting: false,
    };
  },
  created() {
    this.paymentForm();
  },
  methods: {
    paymentForm() {
      let self = this;
      var form = document.getElementsByTagName("form");
      var authorization = "sandbox_8h2f66n7_jp5y6gd3fggntm85";

      client.create(
        {
          authorization: authorization,
        },
        function (err, clientInstance) {
          if (err) {
            console.error(err);
            return;
          }
          hostedFields.create(
            {
              client: clientInstance,
              styles: {
                input: {
                  "font-size": "14px",
                  color: "#253858",
                },
              },
              fields: {
                cardholderName: {
                  selector: "#cc-name",
                  placeholder: "Cardholder Name",
                },
                number: {
                  selector: "#cc-number",
                  placeholder: "Card Number",
                },
                cvv: {
                  selector: "#cc-cvv",
                  placeholder: "CVG/CGC",
                },
                expirationDate: {
                  selector: "#cc-expiration",
                  placeholder: "MM / YY",
                },
              },
            },
            function (err, hostedFieldsInstance) {
              if (err) {
                return;
              }

              function createInputChangeEventListener(element) {
                return function () {
                  validateInput(element);
                };
              }

              function setValidityClasses(element, validity) {
                if (validity) {
                  element.removeClass("is-invalid");
                  element.addClass("is-valid");
                } else {
                  element.addClass("is-invalid");
                  element.removeClass("is-valid");
                }
              }

              function validateInput(element) {
                if (!element.val().trim()) {
                  setValidityClasses(element, false);
                  return false;
                }
                setValidityClasses(element, true);
                return true;
              }

              document
                .querySelector("#cc-name")
                .addEventListener("change", function () {
                  validateInput(this);
                });

              hostedFieldsInstance.on("validityChange", function (event) {
                var field = event.fields[event.emittedBy];
                // Remove any previously applied error or warning classes
                // document.querySelector(field.container)
                field.container.classList.remove("is-valide");
                field.container.classList.remove("is-invalid");

                if (field.isValid) {
                  field.container.classList.add("is-valid");
                } else if (field.isPotentiallyValid) {
                  // skip adding classes if the field is
                  // not valid, but is potentially valid
                } else {
                  field.container.classList.add("is-invalid");
                }
              });

              document
                .getElementById("pay")
                .addEventListener("click", function (event) {
                  self.isSubmitting = true;
                  event.preventDefault();
                  var formIsInvalid = false;
                  var state = hostedFieldsInstance.getState();
                  Object.keys(state.fields).forEach(function (field) {
                    if (!state.fields[field].isValid) {
                      state.fields[field].container.classList.add("is-invalid");
                      formIsInvalid = true;
                    }
                  });

                  if (formIsInvalid) {
                    // skip tokenization request if any fields are invalid
                    self.isSubmitting = false;
                    return;
                  }
                  hostedFieldsInstance.tokenize(function (err, payload) {
                    if (err) {
                      this.isSubmitting = false;
                      return;
                    }
                    self.$emit("submit", payload.nonce);
                    setTimeout(() => {
                      self.isSubmitting = false;
                    }, 1000);
                  });
                });
            }
          );
        }
      );
    },
  },
};
</script>

<style></style>
