<template>
  <validation-provider
    :name="name"
    mode="eager"
    :rules="rules"
    v-slot="{ errors }"
  >
    <b-form-group>
      <b-form-checkbox
        :state="errors[0] ? false : null"
        :name="name"
        @input="input"
        :value="true"
        :unchecked-value="false"
        :checked="value"
      >
        {{ label }}
      </b-form-checkbox>
      <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
    </b-form-group>
  </validation-provider>
</template>

<script>
export default {
  props: {
    name: String,
    value: [String, Boolean, Number, Array],
    rules: String,
    label: String,
    options: Array,
  },
  methods: {
    input(value) {
      this.$emit("input", value);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .bv-no-focus-ring {
    display: flex;
    justify-content: space-between;
    margin: rem(4px) 0;
    .form-check-inline {
      display: flex;
      align-items: center;
      margin: 0;
      &:not(:last-child) {
        margin-right: rem(16px);
      }
      .form-check-input {
        width: 21px;
        min-width: 21px;
        height: 21px;
        padding: 0;
        margin-top: 0;
        border: 2px solid #ccd4e0;
        cursor: pointer;
        &:checked {
          background-color: var(--primary);
          border-color: var(--primary);
        }
        &:focus {
          box-shadow: none;
        }
        &.is-invalid {
          border: 2px solid #dc3545;
        }
      }
      .form-check-label {
        margin-left: 10px;
        font-size: rem(16px);
        color: var(--textPrimary);
        cursor: pointer;
      }
    }
    &.col-form-label {
      font-size: rem(16px);
      font-weight: 500;
      padding-bottom: 12px;
    }
  }
}
</style>
