import Vue from "vue";
import Vuex from "vuex";
import { alert } from "./alert";
import auth from "./auth";
import { campaign } from "./campaign";
import { filters } from "./filters";
import { mediaAccounts } from "./mediaAccounts";
import { common } from "./common";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);
const debug = process.env.NODE_ENV !== "production";
export default new Vuex.Store({
  modules: {
    alert,
    auth,
    campaign,
    filters,
    mediaAccounts,
    common,
  },
  plugins: [
    createPersistedState({
      kay: "influencers",
      paths: ["auth", "mediaAccounts", "filters", "common", "campaign"],
    }),
  ],
  strict: debug,
});
