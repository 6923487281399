import { CAMPAIGN_FILTER, CAMPAIGN_ESTIMATE } from "@/graphql/brand/query";
import { apolloClient } from "@/vue-apollo";
import exceptions from "@/mixin/exceptions";
const state = {
  filters: {},
  estimates: {},
};
const mutations = {
  SET_FILTERS(state, filter) {
    state.filters = filter;
  },
  SET_ESTIMATES(state, estimates) {
    state.estimates = estimates;
  },
};
const getters = {
  filters: (state) => state.filters,
  estimates: (state) => state.estimates,
};
const actions = {
  setDefaultFilters({ dispatch }) {
    dispatch("getFilters");
    dispatch("getEsimates");
  },
  async getFilters({ commit, dispatch }) {
    await apolloClient
      .query({ query: CAMPAIGN_FILTER, fetchPolicy: "network-only" })
      .then((data) => {
        if (data) {
          commit("SET_FILTERS", data.data.newCampaignFilters);
        }
      })
      .catch((e) => {
        exceptions.methods.handleError(e);
      });
  },
  async getEsimates({ commit, dispatch }) {
    await apolloClient
      .query({ query: CAMPAIGN_ESTIMATE })
      .then((data) => {
        if (data) {
          commit("SET_ESTIMATES", data.data.campaignEstimate);
        }
      })
      .catch((e) => {
        console.log(e);
        exceptions.methods.handleError(e);
      });
  },
};

export const campaign = {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
