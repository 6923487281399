import gql from "graphql-tag";

export const LOGGED_IN_USER = gql`
  query {
    me {
      id
      email
      state
      type
      avatar
      first_name
      last_name
      birthdate
      address
      pin
      gender
      phone
      responseRate
      jobOnTime
      completion
      showTour
      location {
        id
        city
        country
        state
      }
      uTags{
        id
        tags
      }
      userAccountState
    }
  }
`;

export const CHECK_USERNAME = gql`
  query usernameAvailable($email: String) {
    usernameAvailable(email: $email) {
      state
      msg
    }
  }
`;
export const AVAILABLE_MEDIA_ACCOUNTS = gql`
  query availableMediaAccounts {
    availableMediaAccounts {
      value: id
      text: name
      logo
      oAuthLink
    }
  }
`;
export const LOCATIONS = gql`
  query locations($search: String) {
    locations(search: $search) {
      id
      city
      state
    }
  }
`;
export const CATEGORIES = gql`
  query categories($search: String) {
    categories(search: $search) {
      id
      name
    }
  }
`;
export const BALANCE = gql`
  query {
    getBalance {
      amount
      currency
    }
  }
`;
