import Vue from "vue";
import VueRouter from "vue-router";
import loginOrRegister from "@/layouts/LoginOrRegister.vue";
import dashboardLayout from "@/layouts/DashboardLayout.vue";
import user from "@/views/user/router";
import brand from "@/views/brand/router";
import userRegister from "@/views/user/router/register";
import brandRegister from "@/views/brand/router/register";
import store from "../store";
Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    component: () =>
      import(/* webpackChunkName: "login.chunk" */ "@/views/common/Login.vue"),
  },
  {
    path: "/login",
    component: () =>
      import(/* webpackChunkName: "login.chunk" */ "@/views/common/Login.vue"),
  },
  {
    path: "/user",
    component: dashboardLayout,
    children: [...user],
  },
  {
    path: "/user/login",
    component: loginOrRegister,
    children: [
      {
        path: "",
        component: () =>
          import(
            /* webpackChunkName: "userLogin.chunk" */ "@/views/user/Login.vue"
          ),
      },
    ],
  },
  {
    path: "/user/register",
    component: loginOrRegister,
    children: [...userRegister],
  },
  {
    path: "/brand",
    component: dashboardLayout,
    children: [...brand],
  },
  {
    path: "/brand/login",
    component: loginOrRegister,
    children: [
      {
        path: "",
        component: () =>
          import(
            /* webpackChunkName: "brandLogin.chunk" */ "@/views/brand/Login.vue"
          ),
      },
    ],
  },
  {
    path: "/brand/register",
    component: loginOrRegister,
    children: [...brandRegister],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  // Check if the user is logged in
  const isLoggedIn = store.getters.isAuthenticated;
  const userType = store.getters.user.type;
  const module = to.fullPath.split("/")[1];
  
  if(to.fullPath == '/') {
    if(isLoggedIn) {
      let startupUrl = "/user/";
      if (userType == 1) {
        startupUrl = "/brand/";
      }
      next({
        path: startupUrl,
      });
    } else {
      window.location.href = process.env.VUE_APP_FRONT_URL;
    }
  } else {
    if (to.matched.some((record) => record.meta.requiresAuth)) {
      if (!isLoggedIn) {
        store.dispatch("logOut");
        next({
          path: module + "/login",
          query: { redirect: to.fullPath },
        });
        store.dispatch("alert/error", "Please login to continue.");
      } else if (!userType == to.meta.userType) {
        let dashboardurl = "/user/";
        if (userType == 1) {
          dashboardurl = "/brand/";
        }
        next({
          path: dashboardurl,
        });
      } else {
        next();
      }
    } else {
      next();
    }
  }
});

export default router;
