import { LOCATIONS, CATEGORIES } from "@/graphql/common/query";
import { apolloClient } from "@/vue-apollo";
const state = {
  locations: [],
  categories: [],
};
const getters = {
  locations: (state) => state.locations,
  categories: (state) => state.categories,
};
const mutations = {
  SET_LOCATIONS(state, locations) {
    state.locations = locations;
  },
  SET_CATEGORIES(state, categories) {
    state.categories = categories;
  },
};
const actions = {
  async getLocations({ commit }) {
    await apolloClient
      .query({ query: LOCATIONS })
      .then((data) => {
        if (data) {
          commit("SET_LOCATIONS", data.data.locations);
        }
      })
      .catch((e) => {});
  },
  async getCategories({ commit }) {
    await apolloClient
      .query({ query: CATEGORIES })
      .then((data) => {
        if (data) {
          commit("SET_CATEGORIES", data.data.categories);
        }
      })
      .catch((e) => {});
  },
};
export const common = {
  namespaced: false,
  state,
  getters,
  mutations,
  actions,
};
