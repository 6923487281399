<template>
  <div>
    <vue-slider
      ref="slider"
      :min="min"
      :max="max"
      v-model="number"
      @dragging="updateValue"
      @drag-end="dragend"
      :lazy="true"
      :disabled="max == 0"
    />
  </div>
</template>

<script>
import "vue-slider-component/theme/antd.css";
export default {
  data() {
    return {
      number: this.value,
    };
  },
  props: {
    min: Number,
    max: Number,
    value: [Number, String, Array],
  },
  components: {
    VueSlider: () =>
      import(/* webpackChunkName: "vueSlider" */ "vue-slider-component"),
  },
  created() {
    this.number = this.value;
  },
  methods: {
    updateValue(value, index) {
      this.$emit("input", value);
    },
    dragend() {
      this.$emit("change", this.number);
    },
  },
  watch: {
    value(newValue) {
      // rule
      this.$nextTick(() => {
        this.$refs.slider.setValue(newValue);
      });
      this.number = newValue;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .vue-slider {
    margin: 15px 0 20px 0;
    &:hover {
      .vue-slider-process {
        background: var(--bgColor);
      }
    }
  }
  .vue-slider-process {
    background: var(--primary) !important;
    border-radius: 1.5px;
  }
  .vue-slider-dot-handle {
    margin-top: -5px;
    background: #fff;
    box-shadow: none;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    border: solid 2px var(--primary) !important;
  }
  .vue-slider:hover .vue-slider-rail {
    background: var(--bgColor);
  }
  .vue-slider-rail {
    height: 2px;
  }
  .vue-slider-dot-tooltip-inner {
    display: none;
  }
}
</style>
