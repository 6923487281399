import gql from "graphql-tag";
import {
  CORE_CAMPAIGN_FIELDS,
  CORE_OFFER_FIELDS,
  CAMPAIGN_DETAILS_FIELDS,
  CAMPAIGN_SUBSCRIPTION_FIELDS,
} from "../fragment";

// export const LOGGED_IN_USER = gql`
//   query {
//     me {
//       id
//       email
//       state
//       type
//       avatar
//       first_name
//       last_name
//       city
//       birthdate
//       address
//       country
//       pin
//       gender
//       phone
//       responseRate
//       jobOnTime
//       completion
//     }
//   }
// `;

// export const CHECK_USERNAME = gql`
//   query usernameAvailable($email: String) {
//     usernameAvailable(email: $email) {
//       state
//       msg
//     }
//   }
// `;

export const NOTIFICATION = gql`
  query {
    notifications {
      id
      timestamp
      title
      image
      url
    }
  }
`;

export const OFFER_DETAILS = gql`
  ${CORE_OFFER_FIELDS}
  query offer($id: Int!) {
    offer(id: $id) {
      ...CoreOfferFields
      details {
        title
        description
        gallery
      }
    }
  }
`;

export const OFFERS_FILTERS = gql`
  query {
    offersFilters {
      locations {
        id: id
        name: city
      }
      categories {
        id
        name
      }
      default {
        locations {
          id: id
          name: city
        }
        categories {
          id
          name
        }
      }
    }
  }
`;

export const OFFERS = gql`
  ${CORE_OFFER_FIELDS}
  query offers(
    $page: Int
    $limit: Int
    $locations: [String]
    $categories: [String]
    $search: String
  ) {
    offers(
      page: $page
      limit: $limit
      locations: $locations
      categories: $categories
      search: $search
    ) {
      ...CoreOfferFields
    }
  }
`;

export const CAMPAIGNS = gql`
  ${CORE_CAMPAIGN_FIELDS}
  ${CAMPAIGN_DETAILS_FIELDS}
  ${CAMPAIGN_SUBSCRIPTION_FIELDS}
  query campaigns(
    $page: Int
    $locations: [String]
    $categories: [String]
    $search: String
    $subscriptions: [String]
    $limit: Int
  ) {
    campaigns(
      page: $page
      locations: $locations
      categories: $categories
      search: $search
      subscriptions: $subscriptions
      limit: $limit
    ) {
      ...CoreCampaignFields
      details {
        ...CampaignDetailsFields
      }
      subscription {
        ...CampaignSubscriptionFields
      }
      category {
        id
        name
      }
      location {
        id: id
        name: city
      }
    }
  }
`;

export const CAMPAIGN_DETAILS = gql`
  ${CORE_CAMPAIGN_FIELDS}
  ${CAMPAIGN_DETAILS_FIELDS}
  ${CAMPAIGN_SUBSCRIPTION_FIELDS}
  query campaign($id: Int) {
    campaign(id: $id) {
      ...CoreCampaignFields
      details {
        ...CampaignDetailsFields
      }
      subscription {
        ...CampaignSubscriptionFields
      }
    }
  }
`;

export const COMPAIGNS_FILTER = gql`
  query {
    campaignsFilters {
      locations {
        id: id
        name: city
      }
      categories {
        id
        name
      }
      default {
        locations {
          id: id
          name: city
        }
        categories {
          id
          name
        }
      }
    }
  }
`;

export const CAMPAIGN_SUBSCRIPTION = gql`
  ${CORE_CAMPAIGN_FIELDS}
  ${CAMPAIGN_SUBSCRIPTION_FIELDS}
  query campaigns(
    $page: Int
    $locations: [String]
    $categories: [String]
    $search: String
    $subscriptions: [String],
    $countCampaigns: Boolean
  ) {
    campaigns(
      page: $page
      locations: $locations
      categories: $categories
      search: $search
      subscriptions: $subscriptions
      countCampaigns: $countCampaigns
    ) {
      ...CoreCampaignFields
      subscription {
        ...CampaignSubscriptionFields
      }
    }
  }
`;
export const MEDIA_ACCOUNT = gql`
  query mediaAccounts($account_id: [Int]) {
    mediaAccounts(account_id: $account_id) {
      id
      name
      logo
      followers
      username
      price
      priceNegotiable
      priceRecommendations {
        story {
          value
          description
        }
        post {
          value
          description
        }
      }
    }
  }
`;
export const WITHDRAW_STATS = gql`
  query {
    withdrawStats {
      income
      withdrawn
      pending
    }
  }
`;
export const WITHDRAWS = gql`
  query withdraws(
    $state: String
    $page: Int
    $limit: Int
    $fromDate: String
    $toDate: String
  ) {
    withdraws(
      state: $state
      page: $page
      limit: $limit
      fromDate: $fromDate
      toDate: $toDate
    ) {
      amount
      state
      method {
        type
      }
    }
  }
`;
//himanshu
export const USERS_TAGS = gql`
  query {
    userTags {
      id
      tags
    }
  }
`;