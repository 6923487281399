<template>
  <div class="locationFilter">
    <!-- Dropdown -->
    <base-multi-select
      :list="this.locations"
      label="city"
      track-by="id"
      @input="input"
      :value="value"
    ></base-multi-select>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  props: {
    value: {
      type: [Object, Array, String],
      default: null,
    },
    selected: [Object, Array, Number],
  },
  computed: {
    ...mapGetters({ locations: "locations" }),
  },
  methods: {
    input(value) {
      this.$emit("input", value);
    },
  },
};
</script>
