export default [
  {
    path: "/",
    name: "Dashboard",
    component: () =>
      import(/* webpackChunkName: "dashboard" */ "@/views/brand/Dashboard.vue"),
    meta: { requiresAuth: true, userType: "1" },
  },
  {
    path: "new-campaign",
    name: "New Camapaign",
    component: () =>
      import(
        /* webpackChunkName: "newCampaign" */
        "@/views/brand/NewCampaign.vue"
      ),
    meta: { requiresAuth: true, userType: "1" },
  },
  {
    path: "influencers",
    name: "Influencers",
    component: () =>
      import(
        /* webpackChunkName: "influencers" */
        "@/views/brand/Influencers.vue"
      ),
    meta: { requiresAuth: true, userType: "1" },
  },
  {
    path: "influencer/:id/:name",
    name: "InfluencersDetail",
    component: () =>
      import(
        /* webpackChunkName: "influencerDetail" */
        "@/views/brand/InfluencerDetail.vue"
      ),
    meta: { requiresAuth: true, userType: "1" },
  },
  {
    // path: "campaign-detail/",
    path: "my-campaign/:id/:name",
    name: "Brand Campaign",
    component: () =>
      import(
        /* webpackChunkName: "campaignDetail" */
        "@/views/brand/MyCampaign.vue"
      ),
    meta: { requiresAuth: true, userType: "1" },
  },
  {
    path: "deals",
    name: "Deals",
    component: () =>
      import(/* webpackChunkName: "deals" */ "@/views/brand/Deals.vue"),
    meta: { requiresAuth: true, userType: "1" },
  },
  {
    path: "deal/:id/:name",
    name: "deal",
    component: () =>
      import(
        /* webpackChunkName: "Deal" */
        "@/views/brand/DealDetail.vue"
      ),
    meta: { requiresAuth: true, userType: "1" },
  },
  {
    path: "billing",
    name: "billing-history",
    component: () =>
      import(/* webpackChunkName: "deals" */ "@/views/brand/Billing.vue"),
    meta: { requiresAuth: true, userType: "1" },
  },
  {
    path: "edit-profile",
    name: "Edit Profile",
    component: () =>
      import(
        /* webpackChunkName: "editProfile" */
        "@/views/brand/EditProfile.vue"
      ),
    meta: { requiresAuth: true, userType: "1" },
  },
  {
    path: "notifications",
    name: "Notifications",
    component: () =>
      import(
        /* webpackChunkName: "notifications" */
        "@/views/brand/Notifications.vue"
      ),
    meta: { requiresAuth: true, userType: "1" },
  },
  {
    path: "verify",
    name: "verify",
    component: () =>
      import(
        /* webpackChunkName: "verifyemail" */
        "@/views/brand/VerifyEmail.vue"
      ),
    meta: { requiresAuth: true, userType: "1" },
  },
  {
    path: "403",
    name: "Brand 403",
    component: () => import(/* webpackChunkName: "offer" */ "@/views/403.vue"),
  },
  {
    path: "404",
    name: "Brand 404",
    component: () => import(/* webpackChunkName: "offer" */ "@/views/404.vue"),
  },
];
