import { Notify } from "notiflix/build/notiflix-notify-aio";
export default {
  methods: {
    handleError(e) {
      if (e) {
        let error = e.message;
        if (e.networkError) {
          if (e.networkError.result.errors) {
            error = e.networkError.result.errors[0].message;
          }
        } else if (e.graphQLErrors) {
          error = e.graphQLErrors[0].message;
        }
        Notify.failure(error);
      }
    },
    getErrorCode(e) {
      let code = null;
      if (e.graphQLErrors) {
        code = e.graphQLErrors[0].extensions.code;
      }
      return code;
    },
  },
};
