<template>
  <div class="dashboard">
    <the-header />
    <main role="main">
      <div class="contentWrapper">
        <div class="container" v-if="user.type == 1 && user.state == 0">
          <b-alert show variant="warning"
            >Your email is not verified.
            <router-link to="/brand/verify">Click Here</router-link> to verify
            your email.</b-alert
          >
        </div>
        <div class="container" v-if="user.type == 0 && user.userAccountState == 0">
          <b-alert show variant="warning"
            >Your have not verified/connected media account.
            <router-link to="/user/edit-profile#connect-media-account">Click Here</router-link> to connect to media account.
          </b-alert>
        </div>
        <router-view />
      </div>
    </main>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "DashboardLayout",
  components: {
    TheHeader: () =>
      import(
        /* webpackChunkName: "theHeader" */ "@/components/layout/TheHeader.vue"
      ),
  },
  computed: {
    ...mapGetters(["user"]),
    ...mapGetters({
        mediaAccounts: "mediaAccounts/list",
    }),
  },
  created() {
    if (this.user.type === 0 && this.user.state === 0) {
      this.setUser();
    }
  },
  methods: {
    ...mapActions(["setUser"]),
  }
};
</script>

<style lang="scss" scoped>
::v-deep {
  .alert {
    a {
      color: var(--primary);
    }
  }
}
</style>
