<template>
  <div>
    <star-rating
      v-model="rating"
      active-color="#9637F1"
      inactive-color="#DABDF7"
      @rating-selected="setRating"
    />
  </div>
</template>

<script>
export default {
  data() {
    return {
      rating: this.value,
    };
  },
  props: {
    value: Number,
  },
  methods: {
    setRating(rating) {
      this.$emit("input", rating);
    },
  },
};
</script>

<style></style>
