import { mapActions } from "vuex";
import { Notify } from "notiflix/build/notiflix-notify-aio";
import { Report } from "notiflix/build/notiflix-report-aio";
export default {
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
    }),
    report(state, message) {
      if (state == "success") {
        Report.success("Done!", message, "OK!");
      }
    },
    notify(state, message) {
      if (state == "success") {
        Notify.success(message);
      } else if (state == "error") {
        Notify.failure(message);
      }
    },
  },
  watch: {
    $route() {
      // clear alert on location change
      this.clearAlert();
    },
  },
};
