import { OFFERS_FILTERS, COMPAIGNS_FILTER } from "@/graphql/user/query";
import { apolloClient } from "@/vue-apollo";
const state = {
  offers: {
    locations: null,
    categories: null,
    default: null,
  },
  campaigns: {
    locations: null,
    categories: null,
    default: null,
  },
};
const mutations = {
  SET_OFFERSFILTERS(state, filters) {
    state.offers.locations = filters.locations;
    state.offers.categories = filters.categories;
    state.offers.default = filters.default;
  },
  SET_CAMPAIGNSFILTERS(state, campaignsFilters) {
    state.campaigns.locations = campaignsFilters.locations;
    state.campaigns.categories = campaignsFilters.categories;
    state.campaigns.default = campaignsFilters.default;
  },
};
const getters = {
  offersFilters: (state) => state.offers,
  campaignFilters: (state) => state.campaigns,
};
const actions = {
  async setFilters({ commit }) {
    await apolloClient
      .query({ query: OFFERS_FILTERS })
      .then((data) => {
        if (data) {
          commit("SET_OFFERSFILTERS", data.data.offersFilters);
        }
      })
      .catch((e) => {});
    await apolloClient
      .query({ query: COMPAIGNS_FILTER })
      .then((data) => {
        if (data) {
          commit("SET_CAMPAIGNSFILTERS", data.data.campaignsFilters);
        }
      })
      .catch((e) => {});
  },
};

export const filters = {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
